import React from 'react';
import * as Styles from './Styles';

const NewUserView = () => {
  return (
    <Styles.Wrapper>
      <h2>You are not a registered Thndr user</h2>
      <Styles.Subtitle>
        Scan the QR code below to download the Thndr app and create your account
      </Styles.Subtitle>
      <Styles.QR src="/QRCode.png" />
    </Styles.Wrapper>
  );
};

export default NewUserView;
